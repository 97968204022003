.nav {
	position: absolute;
	right: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	background: url("../img/MenuBackground.webp");
	&__list {
		padding-left: 3%;
		margin-top: 10vh;
		a {
			color: #359ac0;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 1.5em;
		}

		li {
			padding-top: 5%;
			padding-bottom: 5%;
			list-style-type: none;
		}
	}
}

@media (max-width: 420px) {
	.nav__list {
		padding-left: 0;
		position: absolute;
		bottom: 7%;
		left: 50%;
		transform: translate(-50%, 0);
		text-align: center;
	}
}
